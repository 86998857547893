// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accommodation-index-js": () => import("./../../../src/pages/accommodation/index.js" /* webpackChunkName: "component---src-pages-accommodation-index-js" */),
  "component---src-pages-activities-index-js": () => import("./../../../src/pages/activities/index.js" /* webpackChunkName: "component---src-pages-activities-index-js" */),
  "component---src-pages-copytemplate-js": () => import("./../../../src/pages/copytemplate.js" /* webpackChunkName: "component---src-pages-copytemplate-js" */),
  "component---src-pages-destinations-bangbiangyang-index-js": () => import("./../../../src/pages/destinations/bangbiangyang/index.js" /* webpackChunkName: "component---src-pages-destinations-bangbiangyang-index-js" */),
  "component---src-pages-destinations-index-js": () => import("./../../../src/pages/destinations/index.js" /* webpackChunkName: "component---src-pages-destinations-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-index-js": () => import("./../../../src/pages/info/index.js" /* webpackChunkName: "component---src-pages-info-index-js" */),
  "component---src-pages-intentional-404-js": () => import("./../../../src/pages/Intentional404.js" /* webpackChunkName: "component---src-pages-intentional-404-js" */),
  "component---src-pages-technical-js": () => import("./../../../src/pages/technical.js" /* webpackChunkName: "component---src-pages-technical-js" */),
  "component---src-pages-transport-index-js": () => import("./../../../src/pages/transport/index.js" /* webpackChunkName: "component---src-pages-transport-index-js" */),
  "component---src-pages-traveladvisories-js": () => import("./../../../src/pages/traveladvisories.js" /* webpackChunkName: "component---src-pages-traveladvisories-js" */)
}

